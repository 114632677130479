import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
//import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../shared/components/dashboard/dashboard.service';
import { User } from '../../shared/models/common.models';
import { GenericFilterPipe } from '../../shared/pipes/generic-filter.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';
import { NavMenuComponent } from '../navmenu/navmenu.component';
import { AppService } from './app.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
  standalone: true,
  imports: [/*AngularQueryDevtools,*/ TooltipModule, RouterLink, NavMenuComponent, MatSidenavModule, MatListModule, NgClass, MatButtonModule, RouterLinkActive, MatMenuModule, RouterOutlet, MatProgressSpinnerModule, GenericFilterPipe]
})
export class AppComponent implements OnInit, OnDestroy
{
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  private currentUserInfoRetrievedSubscription: Subscription;
  private loginChangedSubscription: Subscription;

  public router: Router;

  private queryClient = injectQueryClient();

  private authenticationService = inject(AuthenticationService);
  public commonService = inject(CommonService);
  public appService = inject(AppService);
  public dashboardService = inject(DashboardService);

  constructor(private injector: Injector)
  {
    this.router = this.injector.get(Router);

    this.currentUserInfoRetrievedSubscription = this.authenticationService.currentUserInfoRetrieved.subscribe((user: User) =>
    {
      if (user)
      {
        this.commonService.hasUserInfoLoaded.set(true);
        this.commonService.homeStationId = user.HomeStationId;

        this.commonService.initialiseHubs(user.Id);
        this.appService.subscribeToHubEvents(user.Id, user.HomeStationId);

        const currentMonth = this.commonService.today.getMonth() + 1;
        const currentYear = this.commonService.today.getFullYear();

        this.dashboardService.currentUser.set(user);

        this.queryClient.prefetchQuery({
          queryKey: ["DashboardData", user.Id, false, currentMonth, currentYear, false],
          queryFn: () => this.dashboardService.getDashboardData(user.Id, false),
          //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
        });

        if (user.SubordinatesUserIds && user.SubordinatesUserIds.length > 0)
        {
          this.queryClient.prefetchQuery({
            queryKey: ["DashboardData", user.Id, true, currentMonth, currentYear, false],
            queryFn: () => this.dashboardService.getDashboardData(user.Id, true),
            //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
          });
        }

        this.appService.setCalendarDefaults(this.authenticationService.currentUserValue);
      }
    });

    this.loginChangedSubscription = this.authenticationService.loginChanged.subscribe((loggedIn: any) =>
    {
      this.commonService.isLoggedIn.set(loggedIn);
    });



  }

  ngOnInit()
  {

  }



  ngOnDestroy()
  {
    this.appService.unsubscribeFromHubEvents();

    if (this.currentUserInfoRetrievedSubscription)
    {
      this.currentUserInfoRetrievedSubscription.unsubscribe();
    }

    if (this.loginChangedSubscription)
    {
      this.loginChangedSubscription.unsubscribe();
    }



  }





}
