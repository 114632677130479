import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { Subscription } from 'rxjs';
import { CheckBoxFilterComponent } from '../../shared/components/kendo-checkbox-filter.component';
import { KendoExternalEditGridComponent } from '../../shared/components/kendo-external-edit-grid.component';
import { NumericTextBoxFilterComponent } from '../../shared/components/kendo-numerictextbox-filter.component';
import { TextBoxFilterComponent } from '../../shared/components/kendo-textbox-filter.component';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonDataService } from '../../shared/services/common-data.service';
import { CommonService } from '../../shared/services/common.service';
import { KendoGridService } from '../../shared/services/kendo-grid.service';
import { AdministrationService } from '../administration/administration.service';
import { CampaignEditComponent } from './campaign-edit.component';
import { Campaign, CampaignList } from './campaigns.models';


@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
  providers: [KendoGridService],
  standalone: true,
  imports: [MatIconModule, MatPaginatorModule, GridModule, NgClass, ExcelModule, SVGIconModule, NumericTextBoxFilterComponent, TextBoxFilterComponent, CheckBoxFilterComponent, CheckBoxModule, CampaignEditComponent, AsyncPipe, DatePipe]
})
export class CampaignsComponent extends KendoExternalEditGridComponent<CampaignList, Campaign>
{
  private onSavedSubscription: Subscription;

  public icons = {
    excel: fileExcelIcon
  };

  @Input() public gridIsChild: boolean = false;

  constructor(public kendoGridService: KendoGridService,
    private injector: Injector,
    public commonDataService: CommonDataService,
    public commonService: CommonService,
    public administrationService: AdministrationService,
    private authorisationService: AuthorisationService)
  {
    super(kendoGridService, injector, "api/Campaign/GetCampaign", "api/Campaign/CreateCampaign", "api/Campaign/DeleteCampaign", "api/Campaign/GetCampaignsServer", "api/Campaign/UpdateCampaign", authorisationService?.currentuser.ChangedByUserId, "api/Campaign/GetCampaigns");

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCampaigns);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCampaigns);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteCampaigns);
    this.CanExport = true;

  }

  override ngOnInit(): void
  {
    //Need these for server paging
    this.view = this.kendoGridService;
    this.kendoGridService.serverPaging = true;

    this.onSavedSubscription = this.OnSaved.subscribe((data: any) =>
    {
      if (data.IsSuccessful)
      {
        if (data.Message)
        {
          this.commonService.notifySuccess("Success", data.Message);
        }
      }
      else
      {
        let errMsg = "Unable to save record.";

        if (data.ErrorMessage)
        {
          errMsg = data.ErrorMessage;
        }

        this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
      }
    });


    //call after subscriptions
    super.ngOnInit();
  }



  public override addHandler()
  {
    super.addHandler();

    if (this.editDataItem)
    {
      //set defaults for new item.
      this.editDataItem.Id = 0;
      this.editDataItem.ActivityName = "";
      this.editDataItem.Name = "";
      this.editDataItem.Product = "";
      this.editDataItem.BookingId = "";
      this.editDataItem.SortOrder = 0;
      this.editDataItem.Disabled = false;
      this.editDataItem.UpdateActivities = false;
    }
  }

  override ngOnDestroy()
  {
    if (this.onSavedSubscription)
    {
      this.onSavedSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }


  page(event: PageEvent)
  {
    this.gridState.take = event.pageSize;
    this.gridState.skip = (event.pageIndex * event.pageSize);
    this.kendoGridService.read(this.gridState);
  }

}
