import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { ActivitiesInfo } from '../../../shared/models/common.models';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { Campaign } from '../../campaigns/campaigns.models';
import { ActivitiesByDateRangeParameters, Activity, ActivityCreditInfo, ActivityStationInfo, ActivityTab, ActivityTabHeader, CalendarActivity, Property, PropertyStationInfo, Recurrence } from '../calendars.models';
import { CalendarsService } from '../calendars.service';



@Injectable()

export class ActivitiesService
{
  public RecurrenceTypeNone = 0;
  public RecurrenceTypeDaily = 1;
  public RecurrenceTypeWeekly = 2;
  public RecurrenceTypeMonthly = 3;
  public RecurrenceTypeYearly = 4;

  public closeAfterSave: boolean = true;

  public activitiesInfo: ActivitiesInfo;
  public currentUserFullname: string = "";

  private getActivitiesForPropertyAndDateRangeReturnedZeroResults: boolean = false;


  public tabSelectedIndex: WritableSignal<number> = signal(0);
  public activityId: WritableSignal<number> = signal(0);
  public stationIds: WritableSignal<number[]> = signal([]);
  public calendarActivity: WritableSignal<CalendarActivity> = signal(null);
  public activitiesParameters: WritableSignal<ActivitiesByDateRangeParameters> = signal(null);



  public queryActivitiesForPropertyAndDateRange = injectQuery(() =>
  ({
    enabled: this.configurationService.cbSettings().calendarServiceUrl.length > 0 && this.activitiesParameters() != null && !!this.activitiesParameters()?.stationIds && this.activitiesParameters()?.stationIds?.length > 0,
    queryKey: ["Activities", this.activitiesParameters()],
    queryFn: () => this.getRelevantActivities(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public queryActivity = injectQuery(() =>
  ({
    enabled: this.configurationService.cbSettings().calendarServiceUrl.length > 0 && this.activityId() != null && this.activityId() > 0,
    queryKey: ["Activity", this.activityId()],
    queryFn: () => this.calendarsService.getActivity(this.activityId()),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public activities: Activity[] = [];


  public tabs: Signal<ActivityTab[]> = computed(() =>
  {
    let ats: ActivityTab[] = [];

    if (this.activitiesInfo.id != null && this.activitiesInfo.id > 0)
    {
      this.getActivity();
    }
    else
    {
      this.initialiseActivities(this.stationIds());
    }

    if (this.activities && this.activities.length > 0)
    {
      this.activities.map(a =>
      {
        const at: ActivityTab = this.getActivityTab(a);

        if (!ats.some(t => t.content.Id == at.content.Id))
        {
          ats.push(at);
        }
      });
    }

    this.tabSelectedIndex();

    return ats;
  });



  constructor(
    private commonService: CommonService,
    private loggerService: LoggerService,
    private configurationService: ConfigurationService,
    private calendarsService: CalendarsService
  )
  {


  }

  public addActivity(activity: Activity)
  {
    if (!this.activities.some(t => t.Id == activity.Id))
    {
      this.activities.push(activity);
    }
  }

  public removeActivity(activityId: number)
  {
    this.activities = this.activities.filter(a => a.Id == activityId);
    this.setTabSelectedIndex();
  }

  public setTabSelectedIndex()
  {
    this.tabSelectedIndex.set(-1);

    let index = 0;

    if (this.activities && this.activities.length > 0)
    {
      if (this.tabs() != null && this.tabs().length > 0)
      {
        index = this.tabs().length - 1;
      }

      if (index < 0)
      {
        index = 0;
      }
    }

    this.tabSelectedIndex.set(index);
  }

  public async initialiseActivities(stationIds: number[])
  {
    this.closeAfterSave = true;

    this.getActivitiesForPropertyAndDate();
  }

  public async getActivitiesForPropertyAndDate()
  {
    const ca = this.calendarActivity();

    const result: Activity[] = this.queryActivitiesForPropertyAndDateRange.data();

    if (result && result.length > 0)
    {
      this.activities = result;
    }
    else
    {
      this.loggerService.error(`getActivitiesForPropertyAndDateRange returned zero results so had to getActivity only.  User: "${this.currentUserFullname}, parameters: ${ca.PropertyId}|${ca.FirstDateInCalendarRange}|${ca.LastDateInCalendarRange}|${JSON.stringify(this.stationIds())}|${ca.Level}`);
      this.getActivitiesForPropertyAndDateRangeReturnedZeroResults = true;
    }
  }

  public getActivity()
  {
    if (this.activityId() > 0)
    {
      const result: any = this.queryActivity.data();

      if (result && result.Id)
      {
        if (this.getActivitiesForPropertyAndDateRangeReturnedZeroResults)
        {
          result.CalendarActivityId = this.calendarActivity()?.Id;
          result.ActivitiesParameters = this.activitiesParameters();
        }
        else
        {
          result.IsForCampaign = this.activitiesInfo.IsForCampaign;

          this.closeAfterSave = true;
        }

        this.addActivity(result);
      }
    }
  }


  public async getRelevantActivities()
  {
    const ca = this.calendarActivity();
    const ap = this.activitiesParameters();

    let activities: Activity[] = [];

    try
    {
      const campaign: Campaign = this.activitiesInfo?.campaign;

      if (campaign)
      {
        const activity = new Activity();
        activity.CampaignId = campaign.Id;
        activity.IsForCampaign = true;
        activity.BookingId = campaign.BookingId;
        activity.ClientId = campaign.Client.Id;
        activity.ClientName = campaign.Client.Name;
        activity.ClientContact = campaign.Client.Contact;
        activity.ClientContactEmail = campaign.Client.ContactEmail;
        activity.ClientContactPhoneNo = campaign.Client.ContactPhoneNo;
        activity.ClientExecUserId = campaign.ClientExecUserId;
        activity.ClientSupportUserId = campaign.ClientSupportUserId;
        activity.BriefManagerUserId = campaign.BriefManagerUserId;
        activity.NatProjectManagerUserId = campaign.NatProjectManagerUserId;
        activity.Product = campaign.Product;

        activities.push(activity);
      }
      else if (this.activityId() == null || this.activityId() != 0 || this.activitiesInfo?.newActivityOnOccupiedSpot == true)
      {
        //get activities from the database
        activities = await this.calendarsService.getActivitiesForPropertyAndDateRange(ap);

        try
        {
          if (this.activitiesInfo.newActivityOnOccupiedSpot)
          {
            const act: Activity = await this.CreateNewActivity(ca);

            if (act)
            {
              activities.push(act);
            }
          }
        }
        catch (e)
        {

        }
      }
      else
      {
        const activity: Activity = await this.CreateNewActivity(ca);

        if (activity)
        {
          if (!activities.some(t => t.Id == activity.Id))
          {
            //add activites to the array which will be cached by the query function along with the database results
            activities.push(activity);
          }
        }
      }

      if (activities)
      {
        activities.map((activity: any) =>
        {
          if (ca != null)
          {
            activity.CalendarActivityId = ca?.Id;
          }

          if (ap != null)
          {
            activity.ActivitiesParameters = ap;
          }

          activity.isOnline = false;
        });
      }
    }
    catch (e: any)
    {
      console.log(e.message);
    }

    //return results (from database and manually added) to the query function to be cached and returned from query key
    return activities;
  }


  public async CreateNewActivity(ca: CalendarActivity, property: Property = null)
  {
    let activity: Activity = null;

    if (ca)
    {
      activity = new Activity();

      if (property)
      {
        activity.Property = property;
      }
      else if (ca.PropertyId > 0)
      {
        activity.Property = await this.calendarsService.getProperty(ca.PropertyId);
      }

      if (activity.Property)
      {
        if (this.calendarsService.mechanicTypes && this.calendarsService.mechanicTypes.length > 0)
        {
          activity.MechanicType = this.calendarsService.mechanicTypes()[0];
        }

        if (activity.Property && activity.Property.StationSpecificInfo && activity.Property.StationSpecificInfo.length > 0)
        {
          activity.Property.StationSpecificInfo.map((psi: PropertyStationInfo) =>
          {
            if (psi && psi.StationId > 0)
            {
              if (!activity.IsBlocker)
              {
                //if (!act.Stations.some((a: number) => a == psi.StationId))
                //{
                //  act.Stations.push(psi.StationId);
                //}

                const asi: ActivityStationInfo = new ActivityStationInfo();
                asi.ProjectManagerId = psi.ProjectManagerId;
                asi.AdOpsId = psi.AdOpsId;
                asi.StationId = psi.StationId;
                asi.OnAirTime = psi.OnAirTime;
                asi.IsRateCpm = psi.IsRateCpm;
                asi.Rate = psi.Rate;

                asi.ActivityCreditInfos = [];

                psi.PropertyCreditInfos.map(pci =>
                {
                  const aci: ActivityCreditInfo = new ActivityCreditInfo();

                  aci.ActivityId = activity.Id;
                  aci.DayPartId = pci.DayPartId;
                  aci.DayPartLabel = pci.DayPartLabel;
                  aci.Disabled = pci.Disabled;
                  aci.Fri = pci.Fri;
                  aci.Mon = pci.Mon;
                  aci.Sat = pci.Sat;
                  aci.StationId = psi.StationId;
                  aci.Sun = pci.Sun;
                  aci.Thu = pci.Thu;
                  aci.Tue = pci.Tue;
                  aci.Wed = pci.Wed;

                  asi.ActivityCreditInfos.push(aci);
                });

                activity.ActivityStationInfos.push(asi);
                activity.Stations.push(asi.StationId);
              }
              else
              {
                activity.Stations.push(psi.StationId);
              }
            }
          });
        }

        if (activity?.Property?.PropertyType?.IsBroadcast)
        {
          //remove station records for stations apart from the one clicked
          if (!activity.IsBlocker)
          {
            activity.ActivityStationInfos = activity.ActivityStationInfos.filter(asi => ca.StationId == asi.StationId);
          }
          activity.Stations = activity.Stations.filter(asi => ca.StationId == asi);
        }

        activity.EndDate = ca.EndDate;
        activity.Level = ca.Level;
        activity.StartDate = ca.StartDate;
        activity.Id = ca.Id;
        activity.ClientName = ca.ClientName;

        activity.isOnline = false;
        activity.ActivityHeader = `New ${activity.Property != null ? activity.Property.PropertyName : activity.PropertyName} Activity - Pending`;

        const recurrence: Recurrence = new Recurrence();
        recurrence.PatternStartDate = this.commonService.parseJsonDate(activity.StartDate);
        recurrence.PatternEndDate = this.commonService.parseJsonDate(activity.EndDate);
        recurrence.RecurrenceType = this.RecurrenceTypeNone;
        recurrence.Id = 0;

        activity.Recurrence = recurrence;
      }
    }

    return activity;
  }



  private getActivityTab(a: Activity)
  {
    const at: ActivityTab = new ActivityTab();
    at.header = new ActivityTabHeader();

    if (!a.ActivityHeader)
    {
      let status = "Pending";
      a.Status = "Pending";

      if (a.OnHold)
      {
        status = "On Hold";
        a.Status = "OnHold";
      }

      if (a.IsGuaranteed)
      {
        status = "First Right Refusal";
        a.Status = "IsGuaranteed";
      }

      if (a.IsUnConfirmedSold)
      {
        status = "Submitted For Delivery";
        a.Status = "IsUnConfirmedSold";
      }

      if (a.IsSold)
      {
        status = "Confirmed";
        a.Status = "IsSold";
      }

      a.ActivityHeader = `${a.ClientName} ${a.Product} - ${status}`;
    }

    at.header.headerText = a.ActivityHeader;
    at.header.headerClass = this.getClassForActivityStatus(a.Status);
    at.content = a;

    return at;
  }



  public getClassForActivityStatus(status: string)
  {
    let headerClass: any = { 'dialog-header': true, 'pending-activity': true };

    switch (status)
    {
      case "Pending":
        headerClass = { 'dialog-header': true, 'pending-activity': true };
        break;
      case "IsSold":
        headerClass = { 'dialog-header': true, 'sold-activity': true };
        break;
      case "IsGuaranteed":
        headerClass = { 'dialog-header': true, 'guaranteed-activity': true };
        break;
      case "OnHold":
        headerClass = { 'dialog-header': true, 'onhold-activity': true };
        break;
      case "IsUnConfirmedSold":
        headerClass = { 'dialog-header': true, 'unconfirmed-sold-activity': true };
        break;
      default:
        headerClass = { 'dialog-header': true, 'pending-activity': true };
        break;
    }

    return headerClass;
  }



}
