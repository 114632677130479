<form novalidate #activitiesForm="ngForm" style="height:100%" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div class="activities-control">
    <div class="activities-content">
      @if (activitiesService.tabs() && activitiesService.tabs().length>0)
      {
      <div class="activities-header">
        <mat-icon class="drag-handle-icon" cdkDragHandle>drag_handle</mat-icon>
        <div class="tab-group-wrapper">
          <mat-tab-group #tabGroup id="tabGroup" [(selectedIndex)]="activitiesService.tabSelectedIndex">
            @for (tab of activitiesService.tabs(); track tab.content.Id; let index = $index)
            {
            <mat-tab origin="activitiesService.tabs()">
              <ng-template mat-tab-label>
                <div [ngClass]="tab.header.headerClass">
                  <div class="tab-label">
                    {{tab.header.headerText}}
                    <mat-icon class="tab-clear-icon" (click)="removeTab(index)">close</mat-icon>
                  </div>
                </div>
              </ng-template>
              <mat-card appearance="outlined">
                <mat-card-content>
                  <activity [activityData]="tab.content"
                            [spotSoldInRange]="spotSold"
                            [closeAfterSave]="activitiesService.closeAfterSave"
                            [delegateCancelButtonToParent]="true"
                            (activityHeaderTextChange)="activityHeaderTextChange($event)"
                            (statusChange)="statusChange($event)"
                            (copyActivity)="copyActivity($event)"
                            (activitySaved)="activitySaved($event)"
                            (cancelButtonClicked)="cancelButtonClicked($event)">
                  </activity>
                </mat-card-content>
              </mat-card>
            </mat-tab>
            }
          </mat-tab-group>
        </div>
        <div class="add-tab">
          @if (canAddActivity && !spotSold)
          {
          <button mat-icon-button (click)="addTab()" title="Add new activity for property">
            <mat-icon class="add-tab-icon">add_circle</mat-icon>
          </button>
          }
        </div>
      </div>
      }
      @else
      {
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
        <label class="spinner-loading-label">Loading Activities, please wait...</label>
      </div>
      }
    </div>
  </div>
</form>
