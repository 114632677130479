import { Location } from '@angular/common';
import { EventEmitter, Injectable, OnDestroy, inject } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHubProtocol, LogLevel } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { Subscription } from 'rxjs';
import { AuthorisationService } from './authorisation.service';
import { ConfigurationService } from './configuration.service';

@Injectable({ providedIn: 'root' })
export class AppSignalRService implements OnDestroy
{
  private authorisationService: AuthorisationService = inject(AuthorisationService);
  private configurationService: ConfigurationService = inject(ConfigurationService);

  public connectionEstablished = new EventEmitter<boolean>();
  public startConnectionTimeoutDelay = 3000;
  public protocol: IHubProtocol = new MessagePackHubProtocol();
  public autoReconnect = true;

  private _userId = 0;
  private _connectionIsEstablished = false;
  private _hubConnection: HubConnection;

  private connectedSubscription: Subscription;

  constructor(public location: Location)
  {


  }

  createConnection(huburl: string, userId: number)
  {
    this._userId = userId;

    if (!this._hubConnection && this._userId > 0)
    {
      const hubConnectionBuilder: HubConnectionBuilder = new HubConnectionBuilder();

      hubConnectionBuilder.withUrl(huburl, {
        withCredentials: this.authorisationService.access_token() != null && this.authorisationService.access_token() != "",
        accessTokenFactory: () =>
        {
          let token = this.authorisationService.access_token();
          return token ?? '';
        },
        skipNegotiation: false
      });
      hubConnectionBuilder.withHubProtocol(this.protocol);
      hubConnectionBuilder.configureLogging(this.configurationService.cbSettings().signalRLogLevel);

      if (this.autoReconnect)
      {
        hubConnectionBuilder.withAutomaticReconnect(); 
      }

      this._hubConnection = hubConnectionBuilder.build();

      this.hubConnection.onclose((msg) =>
      {
        console.log(msg.message);
        this.startConnection();
      });

      this.hubConnection.onreconnected((connectionId: string) =>
      {
        this.hubConnection.invoke("RegisterConnection", this._userId)
      });
    }
  }

  startConnection()
  {
    if (this._hubConnection.state == HubConnectionState.Disconnected)
    {
      this._hubConnection
        .start()
        .then(() =>
        {
          this._connectionIsEstablished = true;
          console.log('Hub connection started');
          this.connectionEstablished.emit(true);

          this.hubConnection.invoke("RegisterConnection", this._userId);
        })
        .catch(err =>
        {
          this._connectionIsEstablished = false;
          console.log('Error while establishing connection, retrying...');
          console.error(err.message.toString());

          setTimeout(() =>
          {
            this.startConnection();
          }, this.startConnectionTimeoutDelay);
        });
    }
  }

  // ...args: any[] allows you to pass any number of arguments
  run(method: string, ...args: any[])
  {
    switch (this.hubConnection.state)
    {
      case HubConnectionState.Connected:
        this.hubConnection.invoke(method, ...args);
        break;
      case HubConnectionState.Connecting:
        this.connectedSubscription = this.connectionEstablished.subscribe((data: any) =>
        {
          this.hubConnection.invoke(method, ...args);

          this.connectedSubscription.unsubscribe();
        });
        break;
      default:
        this.hubConnection.start()
          .then(() =>
          {
            this.hubConnection.invoke(method, args)

            this.hubConnection.invoke("RegisterConnection", this._userId);
          })
          .catch(err =>
          {
            console.error(err.toString());
            console.error(err.message);
          });
        break;
    }
  }




  get connectionIsEstablished(): boolean
  {
    return this._connectionIsEstablished;
  }

  get hubConnection(): HubConnection
  {
    return this._hubConnection;
  }



  ngOnDestroy()
  {
    if (this.connectedSubscription)
    {
      // prevent memory leak when component destroyed
      this.connectedSubscription.unsubscribe();
    }
  }


}  
