<div class="report-control" [cdkDragDisabled]="dragDisabled" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div class="report-header">
    @if (!dragDisabled)
    {
    <div cdkDragHandle class="report-handle"></div>
    }
    @if (showParameters)
    {
    <div class="report-parameters">
      <label>Report</label>
      <div class="form-group">
        <mat-form-field>
          <mat-select [(ngModel)]="reportService.reportName" (selectionChange)="reportChange($event)" floatPlaceholder="never" floatLabel="never">
            @for (report of reportService.reportListItems | filter:[{show: true}]; track report.name)
            {
            <mat-option [value]="report.value">{{report.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    }
  </div>
  <div class="report-content">
    <tr-viewer #reportViewer id="reportViewer"
               [containerStyle]="viewerContainerStyle"
               [serviceUrl]="serviceUrl"
               [renderingBegin]="onRenderingBegin"
               [renderingEnd]="onRenderingEnd"
               [parametersAreaVisible]="false"
               [parametersAreaPosition]="'LEFT'"
               [reportSource]="reportService.reportSource"
               [parameters]="{ editors: {
                                  singleSelect: 'COMBO_BOX',
                                  multiSelect: 'COMBO_BOX'
                                }
                             }"
               [viewMode]="'INTERACTIVE'"
               [scaleMode]="'SPECIFIC'"
               [pageMode]="'SINGLE_PAGE'"
               [sendEmail]="true"
               [scale]="1.0">
    </tr-viewer>
  </div>
  <div class="report-footer">
    @if (showCloseButton)
    {
    <button mat-button (click)="onCloseClick()">Close</button>
    }
  </div>
</div>


