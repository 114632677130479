@if (!!propertyName && propertyName!=='') {
<div class="property-control" kendoTooltip tooltipClass="tooltip" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="property-header">
    <h1 mat-dialog-title class="dialog-header">{{propertyName}}</h1>
  </div>
  <div class="property-content">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon style="padding-right:0.35rem" title="Property Id: {{form.get('Id').value}}">blur_linear</mat-icon>
          Property
        </ng-template>
        <div class="tab-panel">
          <div class="form-group" style="display:none">
            <mat-form-field>
              <mat-label>Id</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Id" formControlName="Id" readonly="readonly" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select formControlName="CategoryId" placeholder="Category">
                @for (category of calendarsService.categories() | filter:[{Disabled: false}]; track trackByCategories($index, category)) {
                <mat-option [value]="category.Id">{{category.CategoryName}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Property Name</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Property Name" formControlName="PropertyName" maxlength="85" autocomplete="off" />
              @if (form.get('PropertyName').hasError('required')) {
              <mat-error>Please enter a property name</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Property Type</mat-label>
              <mat-select formControlName="PropertyType" placeholder="Property Type" [compareWith]="equalsPT" (selectionChange)="propertyTypeChange($event)">
                @for (propertyType of calendarsService.propertyTypes() | filter:[{Disabled: false}]; track trackByPropertyTypes($index, propertyType)) {
                <mat-option [value]="propertyType">{{propertyType.PropertyTypeName}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-checkbox formControlName="IsPermanent" (ngModelChange)="isPermanentChanged($event)">Is Permanent</mat-checkbox>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" [required]="!form.get('IsPermanent').value" (dateChange)="startDateChange($event)" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
              <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" [min]="form.get('StartDate').value" [required]="!form.get('IsPermanent').value" (dateChange)="endDateChange($event)" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
              <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
            </mat-form-field>
          </div>
          <mat-card appearance="outlined" class="control-mat-card-vertical" style="vertical-align: top">
            @if (isRadioBroadcastPropertyType) {
            <div class="form-group" style="padding-bottom:10px">
              <mat-label>Days Available</mat-label>
              <selectable-chips [data]="commonDataService.daysInTheWeek" formControlName="DaysOfWeek" textField="FirstLetter" valueField="Value" titleField="Name" [isDisabled]="formControlsDisabled">
              </selectable-chips>
            </div>
            }
            @if (!isRadioBroadcastPropertyType) {
            <div class="form-group">
              <mat-form-field>
                <mat-label>Frequency</mat-label>
                <input matInput (focus)="$event.target.select()" placeholder="Frequency" formControlName="Frequency" maxlength="100" autocomplete="off" />
              </mat-form-field>
            </div>
            }
            @if (!isRadioBroadcastPropertyType) {
            <div class="form-group">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-form-field>
                  <mat-label>Season</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="Season" formControlName="Season" type="number" min="0" autocomplete="off" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Episode</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="Episode" formControlName="Episode" type="number" min="0" autocomplete="off" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>No Of Episodes</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="No Of Episodes" formControlName="NoOfEpisodes" type="number" min="0" autocomplete="off" />
                </mat-form-field>
              </mat-card>
            </div>
            }
            @if (!isRadioBroadcastPropertyType) {
            <div class="form-group">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-checkbox formControlName="Pre" (change)="changePre($event)">Pre Roll</mat-checkbox>
                <mat-checkbox formControlName="Mid" (change)="changeMid($event)">Mid Roll</mat-checkbox>
                <mat-checkbox formControlName="Post" (change)="changePost($event)">Post Roll</mat-checkbox>
              </mat-card>
            </div>
            }
            <div class="form-group">
              <kendo-floatinglabel text="{{platformNameLabel}}s">
                @if (!allowOnlyOnePlatformProvider) {
                <kendo-multiselect [autoClose]="false"
                                   [data]="availablePropertyStations"
                                   [textField]="'StationName'"
                                   [valueField]="'Id'"
                                   [valuePrimitive]="true"
                                   [size]="'small'"
                                   [fillMode]="'flat'"
                                   formControlName="Stations"
                                   placeholder="{{platformNameLabel}}s"
                                   [kendoDropDownFilter]="filterSettings"
                                   (valueChange)="handlePropertyStationsChange($event)">
                </kendo-multiselect>
                }
                @if (allowOnlyOnePlatformProvider) {
                <kendo-dropdownlist [data]="availablePropertyStations"
                                    [textField]="'StationName'"
                                    [valueField]="'Id'"
                                    [valuePrimitive]="true"
                                    [size]="'small'"
                                    [fillMode]="'flat'"
                                    [rounded]="'none'"
                                    [(value)]="podcastStationId"
                                    (valueChange)="propertyStationChange($event)">
                </kendo-dropdownlist>
                }
              </kendo-floatinglabel>
            </div>
            <div class="form-group">
              @if (!isRadioBroadcastPropertyType) {
              <kendo-floatinglabel text="Blocked Client Categories" style="padding-bottom: 1.1rem;">
                <kendo-multiselect [autoClose]="false"
                                   [data]="calendarsService.productCategories()"
                                   [textField]="'ProductCategoryName'"
                                   [valueField]="'Id'"
                                   [valuePrimitive]="false"
                                   [size]="'small'"
                                   [fillMode]="'flat'"
                                   formControlName="ProductCategories"
                                   placeholder="Client Categories"
                                   [kendoDropDownFilter]="filterSettings"
                                   (valueChange)="handleProductCategoryChange($event)">
                </kendo-multiselect>
              </kendo-floatinglabel>
              }
            </div>
          </mat-card>
          <mat-card appearance="outlined" class="control-mat-card-vertical" style="padding: 5px 5px 5px 2px;">
            <div class="form-group" style="margin-left: 9px;">
              <mat-form-field>
                <mat-label>External Type</mat-label>
                <input matInput (focus)="$event.target.select()" placeholder="External Type" formControlName="ExternalType" maxlength="100" autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="form-group" style="margin-left: 9px;">
              <mat-form-field>
                <mat-label>External Category</mat-label>
                <input matInput (focus)="$event.target.select()" placeholder="External Category" formControlName="ExternalCategory" maxlength="100" autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="form-group" style="margin-left: 9px;">
              <mat-form-field>
                <mat-label>Sort Order</mat-label>
                <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="checkbox-form-group">
              <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
            </div>
          </mat-card>
          @if (false) {
          <div class="form-group">
            <mat-form-field>
              <mat-label>Activities Per Spot</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Activities Per Spot" matTooltip="Client Activities Per Spot" formControlName="ClientsProppedPerSpot" type="number" min="1" autocomplete="off" />
            </mat-form-field>
          </div>
          }
          @if (false) {
          <div class="form-group">
            <!--Not shown because it governed by property type-->
            <mat-checkbox formControlName="ShowInWhatsOn">Show In What's On</mat-checkbox>
          </div>
          }
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          @if (isRadioBroadcastPropertyType) {
          <fa-icon [icon]="faBroadcastTower" style="padding-right:0.35rem"></fa-icon>
          }
          {{tabLabel}}
        </ng-template>
        <div class="tab-panel" [formGroup]="formSSI">
          <div class="form-group" style="display:block">
            <mat-form-field style="width:85%">
              <mat-label>{{platformNameLabel}}</mat-label>
              <mat-select placeholder="{{platformNameLabel}}" [(value)]="currentStationId" (selectionChange)="stationChange($event)">
                @for (station of propertyStations; track trackByStations($index, station)) {
                <mat-option [value]="station.Id">{{station.StationName}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            @if (form.get('StationSpecificInfo')?.value?.length > 1) {
            <fa-icon [icon]="faClone" style="cursor:pointer" (click)="duplicateCurrentStationInfo()" matTooltip="Duplicate current {{platformNameLabel}} specific information across all {{platformNameLabel}}s."></fa-icon>
            }
          </div>
          <mat-card appearance="outlined" class="control-mat-card-vertical" style="vertical-align: top">
            @if (!isRadioBroadcastPropertyType) {
            <div class="form-group contact-selector-wrapper">
              <fieldset>
                <legend>Vendor Contact Info</legend>
                <div class="contact-item contact-item-name">
                  <mat-label>
                    {{contactNameLabel}}
                    <fa-icon [icon]="faUser" class="contact-icon contact-icon-name"></fa-icon>
                  </mat-label><mat-label>{{currentStationContact}}</mat-label>
                </div>
                <div class="contact-item contact-item-phone">
                  <mat-label>
                    {{contactPhoneLabel}}
                    <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
                  </mat-label><mat-label>{{currentStationContactPhoneNo}}</mat-label>
                </div>
                <div class=" contact-item contact-item-link">
                  <mat-label (click)="stationContactEmailClick($event)">
                    {{contactEmailLabel}}
                    <fa-icon [icon]="faEnvelope" class="contact-icon"></fa-icon>
                  </mat-label><mat-label (click)="stationContactEmailClick($event)">{{currentStationContactEmail}}</mat-label>
                </div>
              </fieldset>
            </div>
            }
            @if (isRadioBroadcastPropertyType) {
            <div class="form-group">
              <contacts-selector [(value)]="currentProjectManager" [data]="projectManagersForStation" (valueChange)="projectManagerChange($event)" hasNoneSelectedOption="true" [isDisabled]="formControlsDisabled">
              </contacts-selector>
            </div>
            }
            @if (!isRadioBroadcastPropertyType) {
            <div class="form-group">
              <contacts-selector [(value)]="currentAdOps" [data]="adOpsForStation" (valueChange)="adOpsChange($event)" hasNoneSelectedOption="true" [isDisabled]="formControlsDisabled">
              </contacts-selector>
            </div>
            }
            <div class="form-group" style="transform: scale(0.9);">
              <mat-label>Excluded Dates</mat-label>
              <md-multi-datepicker [(value)]="currentExclusionDates" [minDate]="exclusionMinDate" (dateClicked)="dateClicked($event)" [isDisabled]="formControlsDisabled">
              </md-multi-datepicker>
            </div>
          </mat-card>
          <div class="rate-spots-form-group">
            @if (showRateCard) {
            <document-uploader formControlName="RateCardUrl"
                               emptyMsg="No Rate Card Uploaded"
                               subFolder="RateCards"
                               linkText="Rate Card"
                               [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                               [recordId]="formSSI.get('PropertyId')?.value"
                               [recordSubId]="formSSI.get('StationId')?.value"
                               [sizeLimit]="configurationService.cbSettings().rateCardSizeLimit"
                               showLabel="false"
                               useFileNameForLinkText="false"
                               labelText="Rate Card"
                               style="margin-bottom: 10px;">
            </document-uploader>
            }
            <mat-card appearance="outlined" class="control-mat-card-one-line">
              <mat-form-field>
                <mat-label>Rate</mat-label>
                <input matInput name="rate" id="rate" (focus)="$event.target.select()" placeholder="Rate" formControlName="Rate" type="number" min="0" (change)="changeRate($event)" [disableControl]="formControlsDisabled" autocomplete="off" />
              </mat-form-field>
              @if (!isRadioBroadcastPropertyType) {
              <mat-checkbox formControlName="IsRateCpm" title="Select if rate is cost per mille (cost per 1000 listeners)">Is Rate CPM</mat-checkbox>
              }
            </mat-card>
            <mat-form-field>
              <mat-label>Media Value</mat-label>
              <input matInput name="mediavalue" id="mediavalue" (focus)="$event.target.select()" placeholder="Media Value" formControlName="MediaValue" type="number" min="0" (change)="changeMediaValue($event)" [disableControl]="formControlsDisabled" autocomplete="off" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Spots Available</mat-label>
              <input matInput name="csps" id="csps" (focus)="$event.target.select()" placeholder="Spots Available" formControlName="ClientsSoldPerSpot" type="number" min="1" (change)="changeSpotsAvailable($event)" [disableControl]="formControlsDisabled" autocomplete="off" />
            </mat-form-field>
            <div class="air-cross-form-group">
              @if (showOnAirCross) {
              <mat-checkbox formControlName="OnAirCross" style="margin-right: 3rem;" (ngModelChange)="onAirCrossChanged($event)">On Air Cross</mat-checkbox>
              }
              @if (showOnAirTime) {
              <kendo-floatinglabel text="On Air Time">
                <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="OnAirTime" [format]="'HH:mm'" [popupSettings]="popupSettings" placeholder=" " (valueChange)="onAirTimeChange($event)"></kendo-timepicker>
              </kendo-floatinglabel>
              }
            </div>
            @if (isRadioBroadcastPropertyType) {
            <div class="form-group" style="transform: scale(0.8) translate(-40px, -50px);">
              <mat-label style="padding-left:5px">{{airtimeCreditsLabel}}</mat-label>
              <div>
                <airtime-credits formControlName="PropertyCreditInfos" (valueChange)="airtimeCreditChange($event)" [isDisabled]="formControlsDisabled"></airtime-credits>
              </div>
            </div>
            }
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="property-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button [disabled]="saveInProgress" (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !formSSI.valid || (!formSSI.dirty && !form.dirty) || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
}
@if (!propertyName) {
<div class="spinner-container">
  <mat-spinner></mat-spinner>
  <label class="spinner-loading-label">Loading Property, please wait...</label>
</div>
}
