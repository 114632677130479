import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, VERSION } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatTableDataSource } from '@angular/material/table';
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from "../../../shared/services/common.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { ActivityExtension } from "../../calendars/calendars.models";
import { ActivityExtensionService } from "./activity-extension.service";
import
{ GridDataResult, AddEvent, RemoveEvent, GridModule } from "@progress/kendo-angular-grid";
import { State, process } from "@progress/kendo-data-query";
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { ActivityExtensionEditComponent } from './activity-extension-edit.component';
import { DropDownListFilterComponent } from '../../../shared/components/kendo-dropdownlist-filter.component';

@Component({
    selector: 'activity-extensions',
    templateUrl: './activity-extensions.component.html',
    styleUrls: ['./activity-extensions.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActivityExtensionsComponent),
            multi: true
        },
        ActivityExtensionService
    ],
    standalone: true,
    imports: [GridModule, DropDownListFilterComponent, ActivityExtensionEditComponent, AsyncPipe, DecimalPipe, DatePipe]
})
export class ActivityExtensionsComponent implements OnInit, ControlValueAccessor
{
  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public editDataItem: ActivityExtension;
  private editedRowIndex: number;
  public isNew: boolean;

  get TotalCosts(): number
  {
    return this.activityExtensionService.activityExtensions?.reduce((sum: any, el: any) => sum += el.TotalCost, 0);
  }

  @Input() get value(): ActivityExtension[]
  {
    return this.activityExtensionService.activityExtensions;
  }
  set value(val: ActivityExtension[])
  {
    this.activityExtensionService.activityExtensions = val;

    this.view = this.activityExtensionService.pipe(
      map((data: any[]) =>
      {
        return process(data, this.gridState);
      })
    );

    if (this.propagateChange)
    {
      this.propagateChange(val);
    }
  }

  private _isDisabled = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;

  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(val: boolean)
  {
    this._isDisabled = val;
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: ActivityExtension[]) => { };


  constructor(
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public activityExtensionService: ActivityExtensionService)
  {
  }


  ngOnInit(): void
  {
    if (!this.value)
    {
      this.activityExtensionService.activityExtensions = [];
    }
  }

  public onStateChange(state: State): void
  {
    this.gridState = state;
  }

  public addHandler(): void
  {
    this.editDataItem = new ActivityExtension();

    this.editDataItem.Id = -(this.activityExtensionService.activityExtensions.filter((i: ActivityExtension) => i.Id < 1).length + 1);

    this.isNew = true;
  }

  public editHandler(args: AddEvent): void
  {
    this.editDataItem = args.dataItem;
    this.editedRowIndex = args.rowIndex;
    this.isNew = false;
  }

  public cancelHandler(): void
  {
    this.editDataItem = undefined;
  }

  public saveHandler(activityExtension: ActivityExtension): void
  {
    this.valueChange.emit(this.value);

    this.activityExtensionService.save(activityExtension, this.isNew, this.editedRowIndex);

    this.editDataItem = undefined;
  }

  public removeHandler(args: RemoveEvent): void
  {
    this.valueChange.emit(this.value);

    this.activityExtensionService.remove(args.dataItem, args.rowIndex);
  }


  ngOnDestroy()
  {

  }


  ngOnChanges(inputs: any): void
  {
    if (this.value)
    {
      if (this.propagateChange)
      {
        this.propagateChange(this.value);
      }

    }

  }

  inputValueChanged(event: any)
  {
    this.valueChange.emit(this.value);

    if (this.propagateChange)
    {
      this.propagateChange(this.value);
    }

    if (this.onTouchedCallback)
    {
      this.onTouchedCallback();
    }
  }



  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }











}

