import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { faBroadcastTower, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { DropDownFilterSettings, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from "../../shared/services/configuration.service";
import { LoggerService } from '../../shared/services/logger.service';
import { CalendarsService } from "../calendars/calendars.service";
import { SearchService } from './search.service';
import { ResolveDataService } from "../../shared/services/resolve-data.service";
import { SearchCategoriesComponent } from "./search-categories/search-categories.component";
import { SearchPropertiesComponent } from "./search-properties/search-properties.component";
import { SearchActivitiesComponent } from "./search-activities/search-activities.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";

import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [SearchService],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatDatepickerModule, MultiSelectModule, MatCheckboxModule, SearchActivitiesComponent, SearchPropertiesComponent, SearchCategoriesComponent]
})
export class SearchComponent implements OnInit, OnDestroy
{
  public form: FormGroup;
  public faBroadcastTower: IconDefinition = faBroadcastTower;

  constructor(
    private fb: FormBuilder,
    public calendarsService: CalendarsService,
    private configurationService: ConfigurationService,
    private searchService: SearchService,
    private commonService: CommonService,
    private resolveDataService: ResolveDataService,
    private loggerService: LoggerService)
  {
    this.form = fb.group({
      StartDate: [],
      EndDate: [],
      IsOnline: [false],
      SearchType: [1],
      Stations: [],
      StationsP: [],
      Name: [""]
    });
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  ngOnInit(): void
  {


  }

  searchTypeChange(event: any)
  {
    //this.resolveDataService.resolveCategories();
  }

  ngOnDestroy()
  {

  }

  get showOnline(): boolean
  {
    let show = true;

    show = this.configurationService.cbSettings().showDigitalCalendar;

    return show;
  }


  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }



}


