import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, VERSION, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEnvelope, faPhone, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ComboBoxModule, DropDownFilterSettings, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { DisableControlDirective } from '../../directives/disable-control-directive';
import { ContactTypeUser } from '../../models/common.models';
import { ContactsSelectorService } from './contacts-selector.service';

@Component({
  selector: 'contacts-selector',
  templateUrl: './contacts-selector.component.html',
  styleUrls: ['./contacts-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactsSelectorComponent),
      multi: true,
    },
    ContactsSelectorService
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FaIconComponent, ComboBoxModule, DisableControlDirective, MatFormFieldModule]
})
export class ContactsSelectorComponent implements OnInit, ControlValueAccessor
{
  public faEnvelope: IconDefinition = faEnvelope;
  public faPhone: IconDefinition = faPhone;
  public faUser: IconDefinition = faUser;

  private _defaultDate: Date;

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: ContactTypeUser) =>
  {
    this.form.controls["contact"].patchValue(this.value);
  };

  private _isDisabled = false;
  private _required = false;
  private _contactNameLabel = "";
  private _contactPopUpClass = "";

  public popupSettings: PopupSettings = {
    appendTo: 'root',
    animate: false
  };

  @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;

  @Input() public contactEmailLabel = "";
  @Input() public contactPhoneLabel = "";
  @Input() public contactMobileLabel = "";
  @Input() public showCombinedPhone = true;
  @Input() public showPhone = false;
  @Input() public showMobile = false;
  @Input() public showEmail = true;
  @Input() public hasNoneSelectedOption = true;

  @Input() get value(): ContactTypeUser
  {
    return this.contactsService.user;
  }
  set value(val: ContactTypeUser)
  {
    this.contactsService.user = val;

    if (this.propagateChange)
    {
      this.propagateChange(val);
    }
  }

  @Input() get data(): ContactTypeUser[]
  {
    return this.contactsService.users;
  }
  set data(val: ContactTypeUser[])
  {
    this.contactsService.users = val;
    this.setupNoneValue();
  }

  @Input() get contactNameLabel(): string
  {
    if (!this._contactNameLabel && this.defaultContactNameLabel)
    {
      this._contactNameLabel = this.defaultContactNameLabel;
    }

    return this._contactNameLabel;
  }
  set contactNameLabel(val: string)
  {
    if (!val && this.defaultContactNameLabel)
    {
      this._contactNameLabel = this.defaultContactNameLabel;
    }
    else
    {
      this._contactNameLabel = val;
    }
  }

  @Input() get contactPopUpClass(): string
  {
    return this._contactPopUpClass;
  }
  set contactPopUpClass(value: string)
  {
    this._contactPopUpClass = value;

    if (this._contactPopUpClass)
    {
      this.popupSettings = {
        ...this.popupSettings,
        popupClass: this._contactPopUpClass
      }
    }
  }


  get defaultContactNameLabel(): string
  {
    let defaultLabel = "";

    if (this.data && this.data.length > 0)
    {
      defaultLabel = this.data[0].ContactTypeLabelText;
      this._contactNameLabel = defaultLabel;

      //if label is still empty because of NONE item and there is more data try again.
      if (!defaultLabel && this.data.length > 1)
      {
        //set label to the first name after the NONE item
        defaultLabel = this.data[1].ContactTypeLabelText;
        this._contactNameLabel = defaultLabel;
      }
    }

    return defaultLabel;
  }

  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(val: boolean)
  {
    this._isDisabled = val;
  }

  @Input() get required(): boolean
  {
    return this._required;
  }
  set required(val: boolean)
  {
    this._required = val;
  }

  form: UntypedFormGroup;

  constructor(private contactsService: ContactsSelectorService, private cdr: ChangeDetectorRef, private fb: UntypedFormBuilder)
  {
    this.form = this.fb.group({
      contact: [{ value: null, disabled: this.isDisabled }]
    });


  }

  //ngAfterViewInit()
  //{
  //  if (this.contactPopUpClass)
  //  {
  //    this.popupSettings = {
  //      ...this.popupSettings,
  //      popupClass: this.contactPopUpClass
  //    }

  //    // this.popupSettings.popupClass = this.contactPopUpClass;
  //  }
  //}

  ngOnInit(): void
  {
    //set value to (none) object if no value
    if (this.data && this.data.length > 0 && !this.value || this.value.HomeStationId == 0)
    {
      this.value = this.data[0];
    }

    if (this.isDisabled)
    {
      this.form.controls["contact"].disable({ emitEvent: false });
    }
    else
    {
      this.form.controls["contact"].enable({ emitEvent: false });
    }

    this.form.controls["contact"].patchValue(this.value, { emitEvent: false });

    this.validate();
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  contactChange(event: any)
  {
    this.selectionChange.emit(new MatSelectChange(this.matSelect, event));
    this.valueChange.emit(event);

    if (this.propagateChange)
    {
      this.propagateChange(event);
    }

    if (this.onTouchedCallback)
    {
      this.onTouchedCallback();
    }
  }

  emailClick(e: any)
  {
    if (this.value && this.value.Email)
    {
      window.location.href = "mailto:" + this.value.Email;
    }
  }

  get combinedPhone(): string
  {
    let phone = "";

    if (this.value)
    {
      if (this.value.Mobile)
      {
        phone = this.value.Mobile;
      }
      else
      {
        phone = this.value.Phone;
      }
    }

    return phone;
  }


  equalsUser(o1: ContactTypeUser, o2: ContactTypeUser)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }


  ngOnDestroy()
  {

  }

  setupNoneValue()
  {
    if (this.hasNoneSelectedOption)
    {
      if (!this.data || this.data.length == 0 || !this.data.some((u: ContactTypeUser) => u.Id == null))
      {
        if (!this.data)
        {
          this.data = [];
        }

        const user: ContactTypeUser = new ContactTypeUser();
        user.FirstName = "(none)";
        user.Surname = "";
        user.FullName = "";
        user.Id = null;
        user.Disabled = false;
        user.Phone = "";
        user.Email = "";
        user.Mobile = "";
        user.SortOrder = 0;

        let stationIdOfCurrentData = 0;

        if (this.data && this.data.length > 0)
        {
          stationIdOfCurrentData = this.data[0].HomeStationId;
          user.HomeStationId = stationIdOfCurrentData;
        }

        this.data.unshift(user);
      }
    }
  }

  ngOnChanges(inputs: any): void
  {
    if (this.propagateChange)
    {
      this.propagateChange(this.value);
    }

    this.validate();
    this.cdr.detectChanges();
  }


  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }

  validate()
  {
    //only validate enabled controls
    if (!this.isDisabled)
    {
      if (this.required && (!this.value || !this.value.Id || this.value.Id == 0))
      {
        this.form.controls['contact'].setErrors({ 'required': true });
        this.form.markAllAsTouched();
      }
    }

    this.cdr.detectChanges();
  }




}
