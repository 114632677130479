import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, Signal, WritableSignal, computed, signal } from '@angular/core';
import { ChartModule, ValueAxisLabels } from '@progress/kendo-angular-charts';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { TileLayoutModule } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { User } from '../../models/common.models';
import { CommonService } from '../../services/common.service';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardNumberComponent } from './dashboard-number/dashboard-number.component';
import { Tile } from './dashboard.models';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SkeletonModule, SwitchModule, TileLayoutModule, DashboardNumberComponent, ChartModule, DashboardListComponent]
})
export class DashboardComponent
{
  private observerSubscription: Subscription;

  public usersGroupName: WritableSignal<string> = signal("");

  private currentScreenSize: string = "xlarge";
  private _tileOne: Tile = null;
  private _tileTwo: Tile = null;
  private _tileThree: Tile = null;
  private _tileFour: Tile = null;
  private _tileFive: Tile = null;
  private _tileSix: Tile = null;
  private _tileSeven: Tile = null;
  private _tileEight: Tile = null;
  private _tileNine: Tile = null;

  public valueAxisLabels: ValueAxisLabels = {
    font: "400 0.75rem 'Open Sans', Roboto, 'Helvetica Neue', sans-serif",
    rotation: 'auto'
  };

  @Input() public groupingItemName: string;

  @Input() public set currentUser(user: User)
  {
    if (user && user.Id > 0)
    {
      this.dashboardService.currentUser.set(user);
    }
  }


  constructor(
    public dashboardService: DashboardService,
    private breakpointObserver: BreakpointObserver,
    private commonService: CommonService)
  {


    this.observerSubscription = breakpointObserver.observe(['(max-width: 950px)', '(max-width: 1280px)', '(max-width: 1440px)', '(max-width: 1680px)', '(max-width: 1920px)'])
      .subscribe((state: BreakpointState) =>
      {
        if (state.breakpoints['(max-width: 950px)'])
        {
          this.currentScreenSize = "xsmall";
        }
        else if (state.breakpoints['(max-width: 1280px)'])
        {
          this.currentScreenSize = "small";
        }
        else if (state.breakpoints['(max-width: 1440px)'])
        {
          this.currentScreenSize = "medium";
        }
        else if (state.breakpoints['(max-width: 1680px)'])
        {
          this.currentScreenSize = "large";
        }
        else if (state.breakpoints['(max-width: 1920px)'])
        {
          this.currentScreenSize = "xlarge";
        }

        this.resetTiles();
      });
  }


  ngOnInit()
  {
    this.setUsersGroupName();
    
  }


  private setUsersGroupName()
  {
    if (this.dashboardService.teamView())
    {
      this.usersGroupName.set(`${this.dashboardService?.currentUser()?.FirstName}${(this.dashboardService?.currentUser()?.FirstName.endsWith("s") ? "'" : "'s")} Team`);
    }
    else
    {
      this.usersGroupName.set(`${this.dashboardService?.currentUser()?.FirstName} ${this.dashboardService?.currentUser()?.Surname}`);
    }
  }

  teamViewChange(teamview: any)
  {
    this.dashboardService.teamView.set(teamview);

    localStorage.setItem("teamView", JSON.stringify(teamview));

    this.setUsersGroupName();

    this.resetTiles();
  }


  get dashboardNumbers()
  {
    return this.dashboardService?.dashboardData()?.DashboardNumbers?.sort((a, b) =>
    {
      // Sort by SortOrder
      // If the first item has a higher number, move it up
      // If the first item has a lower number, move it down
      if (a.SortOrder > b.SortOrder) return 1;
      if (a.SortOrder < b.SortOrder) return -1;

      return 1;
    });
  }

  getTile(id: number): Tile
  {
    let tile: Tile = null;

    if (this.tiles.some(c => c.id == id))
    {
      tile = this.tiles.filter(c => c.id == id)[0];
    }

    return tile;
  }

  get gridListCols(): number
  {
    switch (this.currentScreenSize)
    {
      case "xlarge":
        return 5;
      case "large":
        return 4;
      case "medium":
        return 3;
      case "small":
        return 2;
      case "xsmall":
        return 1;
    }

    return 5;
  }

  public monthlyRevenueTitle: Signal<string> = computed(() =>
  {
    let title = `${this.groupingItemName} Monthly Revenue`;

    if (this.dashboardService.teamView())
    {
      title = `${this.usersGroupName()} Monthly Revenue`;
    }

    return title;
  });


  get tiles(): Tile[]
  {
    switch (this.currentScreenSize)
    {
      case "xlarge":
        return [
          { id: 1, row: 1, col: 1, colSpan: 4, rowSpan: 1, title: '' },
          { id: 2, row: 1, col: 5, colSpan: 1, rowSpan: 2, title: '' },
          { id: 3, row: 2, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
          { id: 4, row: 2, col: 2, colSpan: 3, rowSpan: 1, title: this.monthlyRevenueTitle() },
          { id: 5, row: 3, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
          { id: 6, row: 3, col: 2, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
          { id: 7, row: 3, col: 3, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
          { id: 8, row: 3, col: 4, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
          { id: 9, row: 3, col: 5, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
        ];
      case "large":
        return [
          { id: 1, row: 1, col: 1, colSpan: 3, rowSpan: 1, title: '' },
          { id: 2, row: 1, col: 4, colSpan: 1, rowSpan: 2, title: '' },
          { id: 3, row: 2, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
          { id: 4, row: 2, col: 2, colSpan: 2, rowSpan: 1, title: this.monthlyRevenueTitle() },
          { id: 5, row: 3, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
          { id: 6, row: 3, col: 2, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
          { id: 7, row: 3, col: 3, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
          { id: 8, row: 3, col: 4, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
          { id: 9, row: 5, col: 1, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
        ];
      case "medium":
        return [
          { id: 1, row: 1, col: 1, colSpan: 2, rowSpan: 1, title: '' },
          { id: 2, row: 1, col: 3, colSpan: 1, rowSpan: 2, title: '' },
          { id: 3, row: 2, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
          { id: 4, row: 2, col: 2, colSpan: 1, rowSpan: 1, title: this.monthlyRevenueTitle() },
          { id: 5, row: 3, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
          { id: 6, row: 3, col: 2, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
          { id: 7, row: 3, col: 3, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
          { id: 8, row: 5, col: 1, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
          { id: 9, row: 5, col: 2, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
        ];
      case "small":
        return [
          { id: 1, row: 1, col: 1, colSpan: 1, rowSpan: 1, title: '' },
          { id: 2, row: 1, col: 2, colSpan: 1, rowSpan: 2, title: '' },
          { id: 3, row: 2, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
          { id: 4, row: 3, col: 1, colSpan: 2, rowSpan: 1, title: this.monthlyRevenueTitle() },
          { id: 5, row: 4, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
          { id: 6, row: 4, col: 2, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
          { id: 7, row: 6, col: 1, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
          { id: 8, row: 6, col: 2, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
          { id: 9, row: 8, col: 1, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
        ];
      case "xsmall":
        return [
          { id: 1, row: 1, col: 1, colSpan: 1, rowSpan: 1, title: '' },
          { id: 2, row: 2, col: 1, colSpan: 1, rowSpan: 2, title: '' },
          { id: 3, row: 4, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
          { id: 4, row: 5, col: 1, colSpan: 1, rowSpan: 1, title: this.monthlyRevenueTitle() },
          { id: 5, row: 6, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
          { id: 6, row: 8, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
          { id: 7, row: 10, col: 1, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
          { id: 8, row: 12, col: 1, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
          { id: 9, row: 14, col: 1, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
        ];
    }

    return [
      { id: 1, row: 1, col: 1, colSpan: 4, rowSpan: 1, title: '' },
      { id: 2, row: 1, col: 5, colSpan: 1, rowSpan: 2, title: '' },
      { id: 3, row: 2, col: 1, colSpan: 1, rowSpan: 1, title: 'User Information' },
      { id: 4, row: 2, col: 2, colSpan: 3, rowSpan: 1, title: this.monthlyRevenueTitle() },
      { id: 5, row: 3, col: 1, colSpan: 1, rowSpan: 2, title: 'Activities dropping dead' },
      { id: 6, row: 3, col: 2, colSpan: 1, rowSpan: 2, title: 'Activities on hold' },
      { id: 7, row: 3, col: 3, colSpan: 1, rowSpan: 2, title: 'Credit Lines due' },
      { id: 8, row: 3, col: 4, colSpan: 1, rowSpan: 2, title: 'Final PCRs due' },
      { id: 9, row: 3, col: 5, colSpan: 1, rowSpan: 2, title: 'First Day Air Check' }
    ];
  }

  get tileOne(): Tile
  {
    if (!this._tileOne) 
    {
      this._tileOne = this.getTile(1);
    }

    return this._tileOne;
  }

  get tileTwo(): Tile
  {
    if (!this._tileTwo) 
    {
      this._tileTwo = this.getTile(2);
    }

    return this._tileTwo;
  }

  get tileThree(): Tile
  {
    if (!this._tileThree) 
    {
      this._tileThree = this.getTile(3);
    }

    return this._tileThree;
  }

  get tileFour(): Tile
  {
    if (!this._tileFour) 
    {
      this._tileFour = this.getTile(4);
    }

    return this._tileFour;
  }

  get tileFive(): Tile
  {
    if (!this._tileFive) 
    {
      this._tileFive = this.getTile(5);
    }

    return this._tileFive;
  }

  get tileSix(): Tile
  {
    if (!this._tileSix) 
    {
      this._tileSix = this.getTile(6);
    }

    return this._tileSix;
  }

  get tileSeven(): Tile
  {
    if (!this._tileSeven) 
    {
      this._tileSeven = this.getTile(7);
    }

    return this._tileSeven;
  }

  get tileEight(): Tile
  {
    if (!this._tileEight) 
    {
      this._tileEight = this.getTile(8);
    }

    return this._tileEight;
  }

  get tileNine(): Tile
  {
    if (!this._tileNine) 
    {
      this._tileNine = this.getTile(9);
    }

    return this._tileNine;
  }

  resetTiles()
  {
    this._tileOne = null;
    this._tileTwo = null;
    this._tileThree = null;
    this._tileFour = null;
    this._tileFive = null;
    this._tileSix = null;
    this._tileSeven = null;
    this._tileEight = null;
    this._tileNine = null;
  }

  public labelContent(e: any): string
  {
    return e.category;
  }




















  ngOnDestroy()
  {
    if (this.observerSubscription)
    {
      this.observerSubscription.unsubscribe();
    }
  }





}
