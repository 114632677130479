import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropDownFilterSettings, ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Property, Station } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { PropertyInfoCopier } from '../tools.models';
import { ToolsService } from '../tools.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RadioButtonModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';



@Component({
    templateUrl: 'copy-property-info.component.html',
    styleUrls: ['./copy-property-info.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, ComboBoxModule, LabelModule, RadioButtonModule, MatCheckboxModule, MultiSelectModule, MatButtonModule]
})
export class CopyPropertyInfoComponent implements OnInit
{
  public form: UntypedFormGroup;
  public saveInProgress = false;

  private _properties: Property[] = [];

  constructor(
    public fb: UntypedFormBuilder,
    private confirmDialog: MatDialog,
    public calendarsService: CalendarsService,
    private toolsService: ToolsService
  )
  {
    this.form = this.fb.group({
      StationIds: [],
      FromStationId: [],
      CopyStationData: [],
      PropertyTypeIds: [],
      PropertyId: [],
      ToPropertyIds: [],
      CopyExclusionDates: [],
      CopyAirtimeCredit: [],
      CopySpotsAvailable: [],
      CopyProjectManager: []
    });
  }

  ngOnInit()
  {
    this._properties = this.calendarsService.properties();

    this.form.get('FromStationId').disable({ emitEvent: false });
    this.form.get('ToPropertyIds').disable({ emitEvent: false });

    this.form.get('CopyStationData').patchValue("0", { emitEvent: false });
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  propertyChange(event: any)
  {
    this.form.get('ToPropertyIds').patchValue(null, { emitEvent: false });
    this.form.get('ToPropertyIds').disable({ emitEvent: false });

    if (this.properties != null && this.properties.length > 0)
    {
      this.form.get('ToPropertyIds').enable({ emitEvent: false });
    }

    this.setFromStations();
  }

  matchCopyChanged(event: any)
  {
    this.setFromStations();
  }

  setFromStations()
  {
    this.form.get('FromStationId').patchValue(null, { emitEvent: false });
    this.form.get('FromStationId').disable({ emitEvent: false });

    if (this.form.get('CopyStationData').value == 1 && this.propertyStations != null && this.propertyStations.length > 0)
    {
      this.form.get('FromStationId').enable({ emitEvent: false });
    }
  }

  toPropertiesChange(event: any)
  {
    this.setPropertyTypes();
  }

  setPropertyTypes()
  {
    if (this.form.get('ToPropertyIds').value && this.form.get('ToPropertyIds').value.length > 0)
    {
      this.form.get('PropertyTypeIds').patchValue(null, { emitEvent: false });
      this.form.get('PropertyTypeIds').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('PropertyTypeIds').enable({ emitEvent: false });
    }
  }

  copyInfo()
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to copy this property information as as configured?`;
    confirmDialogRef.componentInstance.confirmTitle = "Copy Property Information?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        const pic: PropertyInfoCopier = this.form.value;

        if (pic)
        {
          this.saveInProgress = true;

          if (!pic.CopyAirtimeCredit)
          {
            pic.CopyAirtimeCredit = false;
          }

          if (!pic.CopyExclusionDates)
          {
            pic.CopyExclusionDates = false;
          }

          if (!pic.CopySpotsAvailable)
          {
            pic.CopySpotsAvailable = false;
          }

          if (!pic.CopyProjectManager)
          {
            pic.CopyProjectManager = false;
          }

          this.toolsService.copyPropertyInfo(pic).then(
            data =>
            {
              this.saveInProgress = false;

              if (data)
              {
                if (data.Message)
                {
                  const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                  confirmDialogRef.componentInstance.confirmMessage = data.Message;
                  confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                  confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                  confirmDialogRef.componentInstance.showCancelButton = false;
                  confirmDialogRef.componentInstance.confirmText = "OK";

                  this.form.reset();
                  this.form.get('CopyStationData').patchValue("0", { emitEvent: false });
                }
              }

            }).catch((e) =>
            {
              this.saveInProgress = false;
            });
        }
      }
    });
  }

  get properties(): Property[]
  {
    //if (this.form.get('PropertyId').value)
    //{
    //  return this._properties.filter(p => p.Id != this.form.get('PropertyId').value);
    //}
    //else
    //{
      return this._properties;
    //}
  }

  get hasSomethingToDo(): boolean
  {
    let hasSomething: boolean = false;

    hasSomething = this.form.get('PropertyId').value
      && (this.form.get('CopyStationData').value == "0" || this.form.get('FromStationId').value)
      && (this.form.get('CopyExclusionDates').value
        || this.form.get('CopyAirtimeCredit').value
        || this.form.get('CopySpotsAvailable').value
        || this.form.get('CopyProjectManager').value)

    return hasSomething;
  }

  get currentProperty(): Property
  {
    return this.calendarsService.properties().filter(p => p.Id == this.form.get('PropertyId').value)[0];
  }

  get propertyStations(): Station[]
  {
    return this.calendarsService.stations().filter((station: Station) => this.currentProperty?.StationLevels?.some((s: any) => station.Id == s.StationId));
  }



}
