import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DropDownFilterSettings, ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ActivityInfo } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { ActivitiesList, ActivityInfoCopier, ActivityListGetParameters } from '../tools.models';
import { ToolsService } from '../tools.service';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';



@Component({
    templateUrl: 'copy-credit-lines.component.html',
    styleUrls: ['./copy-credit-lines.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    ComboBoxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    FloatingLabelModule,
    MultiSelectModule
],
})
export class CopyCreditLinesComponent implements OnInit
{
  public form: UntypedFormGroup;
  public saveInProgress = false;

  public toActivitiesMessage: string = "No activities found";
  public activitiesMessage: string = "No activities found";
  public activityName: string = "";
  public activityId: number = 0;
  public searchControl: UntypedFormControl;

  private _activities: ActivitiesList[] = [];
  private _previousactivities: ActivitiesList[] = [];


  private activityIdChangedSubscription: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    private confirmDialog: MatDialog,
    public calendarsService: CalendarsService,
    public toolsService: ToolsService
  )
  {
    this.form = this.fb.group({
      FromPropertyId: [],
      FromStationId: [],
      StartDate: [],
      EndDate: [],
      ActivityId: [],
      ToActivities: [],
      CopyCreditLines: []
    });


  }

  ngOnInit()
  {
    //This is only to copy credit lines so always true;
    this.form.controls["CopyCreditLines"].patchValue(true, { emitEvent: false });

    this.form.controls["ActivityId"].disable({ emitEvent: false });

    this.searchControl = new UntypedFormControl('');

    this.activityIdChangedSubscription = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value: any) =>
    {
      this.activityName = "";
      this.activityId = 0;
      this.form.controls["ToActivities"].patchValue(null, { emitEvent: true });
      this.activities = [];

      if (value)
      {
        this.disableFromFindControls(true);

        this.form.controls["ActivityId"].patchValue(null, { emitEvent: false });
        this.form.controls["FromPropertyId"].patchValue(null, { emitEvent: false });
        this.form.controls["FromStationId"].patchValue(null, { emitEvent: false });
        this.form.controls["StartDate"].patchValue(null, { emitEvent: false });
        this.form.controls["EndDate"].patchValue(null, { emitEvent: false });

        this.toolsService.getActivity(value).then((data: any) =>
        {
          if (data && data.Id && data.Name)
          {
            this.activityId = data.Id;
            this.activityName = data.Name;           

            this.fillToActivityList();

            let selectedactivities: ActivitiesList[] = this.form.get("ToActivities").value;

            if (selectedactivities && selectedactivities.length > 0)
            {
              selectedactivities = selectedactivities.filter((s: ActivitiesList) => s.Id != this.activityId);
            }

            this.form.controls["ToActivities"].patchValue(selectedactivities, { emitEvent: true });
          }
        });
      }
      else
      {
        this.disableFromFindControls(false);
      }
    });
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  copy(): void
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to copy this activity information as as configured?`;
    confirmDialogRef.componentInstance.confirmTitle = "Copy Activity Information?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result && this.hasSomethingToDo)
      {
        const selectedActivities: ActivitiesList[] = this.form.get("ToActivities").value;

        if (selectedActivities && selectedActivities.length > 0)
        {
          const selectedActivityIds: number[] = [];

          selectedActivities.map((a: ActivitiesList) => selectedActivityIds.push(a.Id));

          const aic: ActivityInfoCopier = new ActivityInfoCopier();
          aic.ActivityId = this.activityId;
          aic.ToActivityIds = selectedActivityIds;

          //This is only to copy credit lines so always true;
          aic.CopyCreditLines = true;

          this.saveInProgress = true;

          this.toolsService.copyActivityInfo(aic).then(
            data =>
            {
              this.saveInProgress = false;

              if (data)
              {
                if (data.Message)
                {
                  const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                  confirmDialogRef.componentInstance.confirmMessage = data.Message;
                  confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                  confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                  confirmDialogRef.componentInstance.showCancelButton = false;
                  confirmDialogRef.componentInstance.confirmText = "OK";

                  this.form.reset();

                  this.searchControl.setValue(null);
                  this.activities = [];
                  this.toolsService.activitiesList = [];
                }
              }

            }).catch((e) =>
            {
              this.saveInProgress = false;
            });
        }
      }
    });
  }

  disableFromFindControls(disable: boolean)
  {
    this.form.controls["ActivityId"].disable({ emitEvent: false });
    this.form.controls["FromPropertyId"].disable({ emitEvent: false });
    this.form.controls["FromStationId"].disable({ emitEvent: false });
    this.form.controls["StartDate"].disable({ emitEvent: false });
    this.form.controls["EndDate"].disable({ emitEvent: false });

    if (!disable)
    {
      this.form.controls["FromPropertyId"].enable({ emitEvent: false });
      this.form.controls["FromStationId"].enable({ emitEvent: false });
      this.form.controls["StartDate"].enable({ emitEvent: false });
      this.form.controls["EndDate"].enable({ emitEvent: false });
    }
  }


  propertyChange(event: any)
  {
    this.resetFromActivities();
  }

  stationChange(event: any)
  {
    this.resetFromActivities();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    this.resetFromActivities();

    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    this.resetFromActivities();

    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }


  resetFromActivities()
  {
    this.activityName = "";
    this.activityId = 0;

    this.activitiesMessage = "No activities found";
    this.form.controls["ActivityId"].disable({ emitEvent: false });
    this.form.controls["ActivityId"].patchValue(null, { emitEvent: false });
    this.toolsService.activitiesList = [];
  }


  fillActivityList()
  {
    if (this.findParametersSet)
    {
      this.resetFromActivities();

      const algp: ActivityListGetParameters = new ActivityListGetParameters();
      algp.StationIds = [];
      algp.PropertyIds = [];

      algp.EndDate = this.form.get("EndDate").value;
      algp.StartDate = this.form.get("StartDate").value;
      algp.StationIds.push(this.form.get("FromStationId").value);
      algp.PropertyIds.push(this.form.get("FromPropertyId").value);

      this.toolsService.getActivitiesList(algp).then((data: any) =>
      {
        if (data && data.length > 0)
        {
          this.activitiesMessage = "Please select activity";

          this.toolsService.activitiesList = data;

          this.form.controls["ActivityId"].enable({ emitEvent: false });
        }
      });
    }
  }

  resetToActivities()
  {
    this.form.controls["ToActivities"].patchValue(null, { emitEvent: true });
    this.activities = [];

    this.toActivitiesMessage = "No activities found";

    this._previousactivities = [];
  }

  fillToActivityList()
  {
    this.resetToActivities();

    this.toolsService.getActivitiesFromActivityClient(this.activityId).then((data: any) =>
    {
      if (data && data.length > 0)
      {
        this.toActivitiesMessage = "Please select activities";

        this.activities = data.filter((d: any) => d.Id != this.activityId);
      }
    });
  }


  activityChange(event: any)
  {
    this.activityName = "";
    this.activityId = 0;
    this.form.controls["ToActivities"].patchValue(null, { emitEvent: true });
    this.activities = [];

    if (event && event.Id > 0)
    {
      this.activityName = event.Name;
      this.activityId = event.Id;
    }

    this.fillToActivityList();
  }

 

  get findParametersSet(): boolean
  {
    const isSet: boolean = this.form.get("FromPropertyId").value && this.form.get("FromStationId").value && this.form.get("StartDate").value && this.form.get("EndDate").value;

    return isSet;
  }



  get activities(): ActivitiesList[]
  {
    return this._activities;
  }
  set activities(value: ActivitiesList[])
  {
    this._activities = value;
  }


  get hasSomethingToDo(): boolean
  {
    let hasSomething: boolean = false;

    hasSomething = this.activityId
      && this.activityId > 0
      && this.form.get("ToActivities").value
      && this.form.get("ToActivities").value.length > 0;

    return hasSomething;
  }



  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.activityIdChangedSubscription)
    {
      this.activityIdChangedSubscription.unsubscribe();
    }

  }


}
